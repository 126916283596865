<template>
  <b-overlay
      :show="users === null"
      rounded="sm"
  >
    <div v-if="users !== null">
      <!-- Table Container Card -->
      <b-card
          no-body
          class="mb-0"
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <b-col
                cols="12"
                md="3"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>نمایش</label>
              <v-select
                  v-model="perPage"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
              />
              <label>تعداد</label>
            </b-col>

            <b-col
                cols="12"
                md="3"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>نمایش</label>
              <v-select
                  v-model="perState"
                  :options="perStateOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50 w-50"
              />
              <label>وضعیت</label>
            </b-col>

            <!-- Search -->
            <b-col
                cols="12"
                md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block mr-1"
                    placeholder="جستجو..."
                />
              </div>
            </b-col>
          </b-row>

        </div>

        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="users"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="موردی موجود نیست!"
        >

          <template #cell(user)="data">
            <b-media vertical-align="center">
              <b-link
                  :to="{ name: 'apps-block-user', params: { id: data.item.user.userId } }"
                  class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.user.name+' '+data.item.user.familyName }}
              </b-link>
              <small class="text-muted">@{{ data.item.user.userId }}</small>
            </b-media>
          </template>

          <!-- Column: type -->
          <template #cell(type)="data">
            <b-badge
                pill
                class="text-capitalize"
                :variant="``"
            >
              {{ data.item.type }}
            </b-badge>
          </template>


          <!-- Column: state -->
          <template #cell(user.state)="data">
            <b-badge
                v-if="data.item.user.state === 1"
                pill
                class="text-capitalize"
                :variant="`success`"
            >
              وارد شده
            </b-badge>
            <b-badge
                v-else-if="data.item.user.state === 2"
                pill
                class="text-capitalize"
                :variant="`warning`"
            >
              فالو نکرده
            </b-badge>
            <b-badge
                v-else-if="data.item.user.state === 3"
                pill
                class="text-capitalize"
                :variant="`danger`"
            >
              آیدی اشتباه
            </b-badge>
          </template>

          <!-- Column: edit -->
          <template #cell(select)="data">
            <b-link :to="{ name: 'apps-block-user', params: { id: data.item.user.userId } }">
              <feather-icon icon="EditIcon" size="20"/>
            </b-link>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
            </b-col>
            <!-- Pagination -->
            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalCounts"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon style="transform: rotate(180deg)"
                                icon="ChevronLeftIcon"
                                size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay,BInputGroup, BInputGroupAppend
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {UserProductBlockGetAllUserRequest} from "@/libs/Api/UserProductBlock";

export default {
  name:'Block',
  title:"مسدود سازی - پنل ادمین مکس ",
  data(){
    return{
      users:null,
      currentPage:1,
      totalCounts:null,
      perPage:10,
      perState:'',
      perStateOptions:[
        {label:'همه',value:''},
        {label:'وارد شده',value:1},
        {label:'فالو نکرده',value:2},
        {label:'آیدی اشتباه',value:3}
      ],
      perPageOptions:[10, 20, 30 , 40 , 50],
      myTableColumns : [
        { key: 'user' ,label :'نام کامل'},
        { key: 'user.instagramId' ,label :'اینستاگرام'},
        { key: 'user.nationalId' ,label :'کد ملی'},
        { key: 'user.mobile',label: 'موبایل'},
        { key: 'user.state' ,label :'وضعیت'},
        { key: 'select',label:'انتخاب کاربر'},
      ],
      searchQuery:'',
      isAddNewUserSidebarActive:false,
    }
  },
  async created() {
    await this.getUsers(this.perPage,this.currentPage,this.searchQuery,this.perState)
  },
  methods:{
    refreshData(){
      this.getUsers(this.perPage,this.currentPage,this.searchQuery,this.perState)
    },
    async getUsers(count,pageNumber,search,state){
      let _this = this;
      let data = {
        pageNumber:pageNumber,
        count:count,
        search:search,
        state:state
      }

      let userProductBlockGetAllUserRequest = new UserProductBlockGetAllUserRequest(_this);
      userProductBlockGetAllUserRequest.setParams(data);
      await userProductBlockGetAllUserRequest.fetch(function (content){
         _this.users = content.results;
        _this.totalCounts = content.count;
      },function (error){
        console.log(error);
      })
    }
  },
  watch:{
    perPage:function (nv,ov){
      this.getUsers(nv,this.currentPage,this.searchQuery,this.perState);
    },
    currentPage:function (nv,ov) {
      this.getUsers(this.perPage,nv,this.searchQuery,this.perState);
    },
    searchQuery:function (nv,ov){
      this.getUsers(this.perPage,this.currentPage,nv,this.perState);
    },
    perState:function (nv,ov){
      this.getUsers(this.perPage,this.currentPage,this.searchQuery,nv);
    }
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BInputGroup, BInputGroupAppend,

    vSelect,
  },
}
</script>

<style lang="scss" scoped>

</style>