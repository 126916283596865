import WebServiceRequest from '../Api/WebServiceRequest'

class UserProductBlockCreateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('UserProductBlock/Create')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class UserProductBlockUpdateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('UserProductBlock/Update')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class UserProductBlockGetAllRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('UserProductBlock/GetAll')
    }
    setParams(data){
        super.setRequestParam(data);
    }
}
class UserProductBlockGetRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setUserId(id){
        super.setTag('UserProductBlock/Get?userProductBlockId='+id)
    }
}
class UserProductBlockDeleteRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setUserId(id){
        super.setTag('UserProductBlock/Delete?id='+id)
    }
}
class UserProductBlockGetAllUserRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('UserProductBlock/GetAllUser')
    }
    setParams(data){
        super.setRequestParam(data);
    }
}
class UserProductBlockGetBlockedProductsRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('UserProductBlock/GetBlockedProducts')
    }
    setParams(data){
        super.setRequestParam(data);
    }
}
class UserProductBlockToggleProductBlockRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('UserProductBlock/ToggleProductBlock')
    }
    setData(data){
        super.setRequestParamDataObj(data);
    }
}

export {
    UserProductBlockCreateRequest,
    UserProductBlockUpdateRequest,
    UserProductBlockGetAllRequest,
    UserProductBlockGetRequest,
    UserProductBlockDeleteRequest,
    UserProductBlockGetAllUserRequest,
    UserProductBlockGetBlockedProductsRequest,
    UserProductBlockToggleProductBlockRequest
};
